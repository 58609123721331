<template>
  <TicketItemTemplate />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TicketItemTemplate from '@/components/Tickets/TicketItemTemplate'

export default defineComponent({
  name: 'TicketsItem',
  components: {
    TicketItemTemplate,
  },
  setup() {
    //
  },
})
</script>
